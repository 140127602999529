import React from "react"

export const Testimonial = ({ testimonial }) => {
  return (
    <div className="bg-light p-5 my-5 text-center ">
      <blockquote>
        {testimonial.body && (
          <div
            className="content-body"
            dangerouslySetInnerHTML={{
              __html: testimonial.body.childMarkdownRemark.html,
            }}
          />
        )}
      </blockquote>
      <p className="lead mb-0">{testimonial.author}</p>
    </div>
  )
}
