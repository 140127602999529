import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { CurveCarveTop, CurveCarveBottom } from "../components/Curves/Curve"
import { Newsletter } from "../components/Misc/Newsletter"
import { graphql } from "gatsby"
import { Testimonial } from "../components/Testimonial/Testimonial"

const Testimonials = ({ data }) => {
  const {
    allContentfulTestimonial: { nodes: testimonials },
  } = data

  // console.log(testimonials)

  return (
    <Layout>
      <SEO
        title="Testimonials"
        description="Investing in property is a big decision and it is understandable that you might feel nervous about taking the next step. Over our 11+ years in the business we have helped hundreds of investors create better futures; hear their testimonials and reviews of our customer service."
      />
      <Container>
        <Row>
          <Col>
            <h1>Testimonials</h1>
            <p className="lead">
              Read how other investors have benefited from the useful guidance
              and expert knowledge of the consultative team at One Touch.
              Helping you from start to finish.
            </p>
          </Col>
        </Row>
      </Container>

      <CurveCarveTop variant="primary" />
      <div className="bg-primary overflow-auto">
        <Container>
          {testimonials.map(testimonial => {
            return (
              <Testimonial key={testimonial.id} testimonial={testimonial} />
            )
          })}
        </Container>
      </div>
      <CurveCarveBottom variant="primary" />
      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query TestimonialPageQuery {
    allContentfulTestimonial {
      nodes {
        id
        author
        body {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default Testimonials
